import { createBrowserHistory, History } from "history";

interface IState {
    [key: string]: any;
    from: string;
}

declare module "history" {
    export interface History<HistoryLocationState> {
        goBackOrDefault(this: History<HistoryLocationState>, defaultUrl: string): void;
        pushWithCurrent(this: History<HistoryLocationState>, url: string): void;
    }
}

const history = createBrowserHistory<IState>();

function goBackOrDefault(this: History<IState>, defaultUrl: string) {
    this.location.state?.from ? this.goBack() : this.push(defaultUrl);
}
history.goBackOrDefault = goBackOrDefault.bind(history);

function pushWithCurrent(this: History<IState>, url: string) {
    this.push(url, { from: this.location.pathname + this.location.search });
}
history.pushWithCurrent = pushWithCurrent.bind(history);

export default history;
