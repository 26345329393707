import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { PermissionName, usePermissions } from "@client/auth";

export interface IAuthorizedRouteProps extends Omit<RouteProps, "render"> {
    path: string | string[];
    permission?: PermissionName | PermissionName[];
    mode?: "any" | "every";
}

const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = ({
    children,
    permission = [],
    mode = "every",
    component,
    ...rest
}) => {
    const permissions = usePermissions();
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const requiredPermissions = Array.isArray(permission) ? permission : [permission];
                const authorized =
                    mode === "every"
                        ? requiredPermissions.every((rp) => permissions.includes(rp))
                        : requiredPermissions.some((rp) => permissions.includes(rp));

                const Component: any = component;
                return authorized ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

export default AuthorizedRoute;
