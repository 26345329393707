import React, { FC, PropsWithChildren, useState, useEffect } from "react";
import { useHistory, matchPath } from "react-router-dom";
import { compile } from "path-to-regexp";

import { TransparentLoader } from "components/loader";
import { IAuthorizedRouteProps } from "@client/components/authorized-route";
import { useGetStartedQuery } from "@client/utils/query";
import { useCanShowGetStarted } from "@client/utils/hooks";
import { usePermissions } from "./with-authentication";
import useOrg from "./use-org";
import { PermissionName, signupPermissions } from "./permissions";

export interface IRedirectRoute {
    fromPath: string;
    toPath: string;
}

interface IProps {
    defaultPath: string;
    routes: IAuthorizedRouteProps[];
    redirectRoutes?: IRedirectRoute[];
}

const permissionsOrNull = (permission: PermissionName | PermissionName[] | undefined) => {
    if (permission) {
        return Array.isArray(permission) ? permission : [permission];
    }

    return null;
};

const WithDefaultRouteSelected: FC<PropsWithChildren<IProps>> = ({ children, routes, defaultPath, redirectRoutes }) => {
    const permissions = usePermissions();

    const history = useHistory<{ afterLogin?: boolean }>();

    const pathname = history.location.pathname;
    const { org, isSetupComplete } = useOrg();
    const [shouldRender, setShouldRender] = useState<boolean>(false);

    const { notFinished, loaded, loading } = useGetStartedQuery({ disableLoad: false, shouldReset: true });
    const canShowGetStarted = useCanShowGetStarted();

    useEffect(() => {
        if (shouldRender) {
            return;
        }

        if (!isSetupComplete) {
            const path = signupPermissions.every((p) => permissions.includes(p)) ? `/${org}/signup` : "/not-found";
            history.replace(path);
            return;
        }

        if (!loaded || loading) {
            return;
        }

        if (
            notFinished! > 0 &&
            permissions.includes(PermissionName.OrgClientReadGetStartedState) &&
            pathname === `/${org}` &&
            canShowGetStarted
        ) {
            history.replace(`/${org}/get-started`);
            return;
        }

        let routeFound = false;
        let redirectTo: string | undefined;
        let matched = false;
        for (let i = 0; i < routes.length; i += 1) {
            const { path, exact, mode = "every" } = routes[i];
            const permission = permissionsOrNull(routes[i].permission);
            const authorized =
                !permission ||
                (mode === "every"
                    ? permission.every((p) => permissions.includes(p))
                    : permission.some((p) => permissions.includes(p)));
            const currentMatched = matchPath(pathname, { path, exact });
            matched = matched || !!currentMatched;
            if (authorized) {
                if (currentMatched) {
                    routeFound = true;
                    break;
                } else if (!redirectTo) {
                    redirectTo = Array.isArray(path) ? path[0] : path;
                }
            }
        }

        if (!matched) {
            let redirectFound = false;
            if (redirectRoutes) {
                for (let i = 0; i < redirectRoutes.length; i += 1) {
                    const { fromPath, toPath } = redirectRoutes[i];
                    const fromMatched = matchPath(pathname, { path: fromPath, exact: true });
                    if (fromMatched) {
                        history.replace(compile(toPath)({ org }));
                        redirectFound = true;
                        break;
                    }
                }
            }

            if (!redirectFound) {
                history.replace("/not-found");
            }
        } else if (!routeFound) {
            history.push(compile(redirectTo ?? defaultPath)({ org }));
        }

        setShouldRender(true);
    }, [
        canShowGetStarted,
        defaultPath,
        history,
        isSetupComplete,
        loaded,
        loading,
        notFinished,
        org,
        pathname,
        permissions,
        routes,
        shouldRender,
        redirectRoutes,
    ]);

    if (!shouldRender) {
        return <TransparentLoader loading />;
    }

    return <>{children}</>;
};

export default WithDefaultRouteSelected;
