import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { TransparentLoader } from "components/loader";
import AuthorizedRoute, { IAuthorizedRouteProps } from "@client/components/authorized-route";
import BcmInvoiceDownload from "@client/pages/bcm-invoice-download";
import { WithDefaultRouteSelected, IRedirectRoute } from "./auth";
import SecureRoute from "./auth/with-authentication";
import { PermissionName, signupPermissions } from "./auth/permissions";

const Layout = lazy(() => import("@client/components/layout"));
const Login = lazy(() => import("./pages/login"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Signup = lazy(() => import("./pages/signup"));
const NewOrganisation = lazy(() => import("./pages/new-client/NewClient"));
const NewOrganisationSetup = lazy(() => import("./pages/new-client/new-client-setup"));
const Users = lazy(() => import("./pages/users"));
const Payments = lazy(() => import("./pages/payments"));
const Payment = lazy(() => import("./pages/payment"));
const Organisation = lazy(() => import("./pages/organisation"));
const ListPaycadaApiOrgs = lazy(() => import("./pages/paycada-api/list-paycada-api-orgs"));
const Stripe = lazy(() => import("./pages/organisation/Stripe"));
const Payout = lazy(() => import("./pages/organisation/Payout"));
const TemplatesList = lazy(() => import("./pages/templates/TemplatesList"));
const ConfigsList = lazy(() => import("./pages/new-smtp-configuration/ConfigsList"));
const SendCommunication = lazy(() => import("./pages/send-communication"));
const InvoiceSync = lazy(() => import("./pages/accounting-system-sync"));
const CreditManagementCustomerCommunication = lazy(() => import("./pages/credit-manager-customer-communication"));
const SearchCustomerForPaymentLink = lazy(
    () => import("./pages/credit-manager-customer-communication/search-customer-and-send-link")
);
const BillingCustomers = lazy(() => import("./pages/billing-customers"));
const ClientSubscription = lazy(() => import("./pages/client-subscription"));
const Customers = lazy(() => import("./pages/customers"));
const Customer = lazy(() => import("./pages/customer"));
const Profile = lazy(() => import("./pages/profile"));
const NotFound = lazy(() => import("./pages/not-found"));
const SelectOrganisation = lazy(() => import("./pages/select-organisation"));
const BrandingSettings = lazy(() => import("./pages/branding-settings"));
const Settings = lazy(() => import("./pages/settings"));
const Verify = lazy(() => import("./pages/verify"));
const SetupProfile = lazy(() => import("./pages/setup-profile"));
const Billing = lazy(() => import("./pages/billing"));
const PaymentsSettings = lazy(() => import("./pages/payments-settings"));
const BrandingPreview = lazy(() => import("./pages/branding-preview"));
const AccountingSoftware = lazy(() => import("./pages/accounting-software"));
const GetStarted = lazy(() => import("./pages/get-started"));
const ClientState = lazy(() => import("./pages/client-state"));
const ReviewApplication = lazy(() => import("./pages/review-application"));
const ClientSettings = lazy(() => import("./pages/client-settings"));
const Affiliate = lazy(() => import("./pages/affiliate"));
const Messages = lazy(() => import("./pages/messages"));
const CustomerCommunication = lazy(() => import("./pages/customer-communication"));
const CodatComplete = lazy(() => import("@client/pages/codat-complete"));

const validChars = "bcdfghjkmnpqrstvwxyz123456789";
const paramRegex = `[${validChars}]{4}-[${validChars}]{4}-[${validChars}]{4}`;

const sharedRoutes: IAuthorizedRouteProps[] = [
    {
        path: "/:org/users",
        permission: PermissionName.OrgReadUserTeams,
        component: Users,
        exact: true,
    },
    {
        path: "/:org/profile",
        component: Profile,
        exact: true,
    },
];

const clientOnlyRoutes: IAuthorizedRouteProps[] = [
    {
        path: "/:org",
        component: Customers,
        permission: PermissionName.DebtClientReadCustomers,
        exact: true,
    },
    {
        path: "/:org/get-started",
        component: GetStarted,
        permission: PermissionName.OrgClientReadGetStartedState,
        exact: true,
    },
    {
        path: `/:org/customers/:id(${paramRegex})`,
        permission: PermissionName.DebtClientReadCustomers,
        component: Customer,
        exact: true,
    },
    {
        path: "/:org/payments",
        permission: PermissionName.PaymentClientReadPayments,
        component: Payments,
        exact: true,
    },
    {
        path: `/:org/payments/:id(${paramRegex})`,
        permission: PermissionName.PaymentClientReadPayments,
        component: Payment,
        exact: true,
    },
    {
        path: "/:org/settings/branding",
        permission: PermissionName.OrgClientWriteThemeSettings,
        component: BrandingSettings,
        exact: true,
    },
    {
        path: "/:org/settings/organisation",
        permission: [PermissionName.OrgReadSettings, PermissionName.OrgClientReadAffiliateSchemeState],
        mode: "any",
        component: Settings,
        exact: true,
    },
    {
        path: "/:org/settings/billing",
        component: Billing,
        permission: PermissionName.OrgClientReadPlans,
        exact: true,
    },
    {
        path: "/:org/settings/payments",
        component: PaymentsSettings,
        permission: [PermissionName.OrgClientCreatePaymentAccount, PermissionName.OrgClientReadBankTransferSettings],
        mode: "any",
        exact: true,
    },
    {
        path: "/:org/settings/accounting-software",
        component: AccountingSoftware,
        permission: PermissionName.OrgClientReadAccountingSystem,
        exact: true,
    },
    {
        path: "/:org/settings/customer-communication",
        component: CustomerCommunication,
        permission: PermissionName.OrgReadSettings,
        exact: true,
    },
];

const itOpsOnlyRoutes: IAuthorizedRouteProps[] = [
    {
        path: "/:org/create",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        component: Organisation,
        exact: true,
    },
    {
        path: "/:org/paycada-api",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        component: ListPaycadaApiOrgs,
        exact: true,
    },
    {
        path: "/:org/stripe",
        permission: PermissionName.OrgItOpsSetPaymentSystemIdForClient,
        component: Stripe,
        exact: true,
    },
    {
        path: "/:org/templates",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        component: TemplatesList,
        exact: true,
    },
    {
        path: "/:org/test-payout",
        permission: PermissionName.OrgItOpsCreateClientTestPayout,
        component: Payout,
        exact: true,
    },
    {
        path: "/:org/send-communication",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        component: SendCommunication,
        exact: true,
    },
    {
        path: "/:org/smtp-config",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        component: ConfigsList,
        exact: true,
    },
    {
        path: "/:org/sync-invoice",
        permission: PermissionName.OrgItOpsPerformAccountingSystemSync,
        component: InvoiceSync,
        exact: true,
    },
    {
        path: "/:org/billing-customers",
        permission: PermissionName.OrgItOpsCallCreateOrUpdateEventOnClients,
        component: BillingCustomers,
        exact: true,
    },
    {
        path: "/:org/client-subscription",
        permission: PermissionName.OrgItOpsWriteSubscriptions,
        component: ClientSubscription,
        exact: true,
    },
    {
        path: "/:org/client-theme",
        permission: PermissionName.OrgItOpsWriteThemeSettings,
        component: BrandingSettings,
        exact: true,
    },
    {
        path: "/:org/client-state",
        component: ClientState,
        permission: PermissionName.OrgItOpsEnableDisableClient,
        exact: true,
    },
    {
        path: "/:org/client-settings",
        component: ClientSettings,
        permission: PermissionName.ItOpsReadClientSettings,
        exact: true,
    },
    {
        path: "/:org/create-affiliate",
        component: Affiliate,
        permission: PermissionName.ItOpsWriteAffiliate,
        exact: true,
    },
    {
        path: "/:org/messages",
        component: Messages,
        permission: PermissionName.OrgItOpsRaiseMessage,
        exact: true,
    },
];

const creditManagementOnlyRoutes: IAuthorizedRouteProps[] = [
    {
        path: `/:org/send-payment-link`,
        permission: PermissionName.CreditManagerSendPaymentLink,
        component: SearchCustomerForPaymentLink,
        exact: true,
    },
    {
        path: `/:org/send-payment-link/:id(${paramRegex})`,
        permission: PermissionName.CreditManagerSendPaymentLink,
        component: CreditManagementCustomerCommunication,
        exact: true,
    },
    {
        path: "/:org/review-application",
        component: ReviewApplication,
        permission: PermissionName.CreditManagerReviewClientApplication,
        exact: true,
    },
];

const itOpsRedirectRoutes: IRedirectRoute[] = [
    {
        fromPath: "/:org",
        toPath: "/:org/users",
    },
];

const creditManagementRedirectRoutes: IRedirectRoute[] = [
    {
        fromPath: "/:org",
        toPath: "/:org/send-payment-link",
    },
];

const allClientRoutes: IAuthorizedRouteProps[] = clientOnlyRoutes.concat(sharedRoutes);
const allItOpsRoutes: IAuthorizedRouteProps[] = itOpsOnlyRoutes.concat(sharedRoutes);
const allCreditManagementRoutes: IAuthorizedRouteProps[] = creditManagementOnlyRoutes.concat(sharedRoutes);

const Routes: React.FC = () => (
    <Suspense fallback={<TransparentLoader loading />}>
        <Switch>
            <AuthorizedRoute
                exact
                path="/:org/signup"
                component={NewOrganisationSetup}
                permission={signupPermissions}
            />
            <AuthorizedRoute
                exact
                path="/:org/codat-complete"
                component={CodatComplete}
                permission={signupPermissions}
            />
            <AuthorizedRoute exact path="/:org/branding-preview" component={BrandingPreview} />
            <AuthorizedRoute
                path="/:org(credit-management)/download-pdf"
                component={BcmInvoiceDownload}
                permission={PermissionName.CreditManagerLoadInvoiceDocument}
                exact
            />
            <Route path="/:org(credit-management)">
                <WithDefaultRouteSelected
                    routes={allCreditManagementRoutes}
                    redirectRoutes={creditManagementRedirectRoutes}
                    defaultPath="/:org/profile"
                >
                    <Layout>
                        <Suspense fallback={<TransparentLoader loading sx={{ mt: "100px" }} />}>
                            <Switch>
                                {allCreditManagementRoutes.map((route) => (
                                    <AuthorizedRoute
                                        key={Array.isArray(route.path) ? route.path[0] : route.path}
                                        {...route}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </Layout>
                </WithDefaultRouteSelected>
            </Route>
            <Route path="/:org(it-ops)">
                <WithDefaultRouteSelected
                    routes={allItOpsRoutes}
                    redirectRoutes={itOpsRedirectRoutes}
                    defaultPath="/:org/profile"
                >
                    <Layout>
                        <Suspense fallback={<TransparentLoader loading sx={{ mt: "100px" }} />}>
                            <Switch>
                                {allItOpsRoutes.map((route) => (
                                    <AuthorizedRoute
                                        key={Array.isArray(route.path) ? route.path[0] : route.path}
                                        {...route}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </Layout>
                </WithDefaultRouteSelected>
            </Route>
            <Route>
                <WithDefaultRouteSelected routes={allClientRoutes} defaultPath="/:org/profile">
                    <Layout>
                        <Suspense fallback={<TransparentLoader loading sx={{ mt: "100px" }} />}>
                            <Switch>
                                {allClientRoutes.map((route) => (
                                    <AuthorizedRoute
                                        key={Array.isArray(route.path) ? route.path[0] : route.path}
                                        {...route}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </Layout>
                </WithDefaultRouteSelected>
            </Route>
        </Switch>
    </Suspense>
);

const App = () => {
    const { isAuthenticated } = useAuth0();
    const defaultPath = isAuthenticated ? "/not-found" : "/";
    return (
        <Switch>
            <Route
                path={[
                    "/",
                    "/setup-profile",
                    "/signup",
                    "/signup/new-organisation",
                    "/login",
                    "/not-found",
                    "/select-organisation",
                    "/verify",
                    "/reset-password",
                ]}
                exact
            >
                <Suspense fallback={<TransparentLoader loading />}>
                    <Switch>
                        <Route path="/signup" exact component={Signup} />
                        <Route path="/signup/new-organisation" exact component={NewOrganisation} />
                        <Route path={["/", "/login"]} exact component={Login} />
                        <Route path="/select-organisation" exact component={SelectOrganisation} />
                        <Route path="/setup-profile" exact component={SetupProfile} />
                        <Route path="/verify" exact component={Verify} />
                        <Route path="/reset-password" exact component={ResetPassword} />
                        <Route path={defaultPath} exact component={NotFound} />
                    </Switch>
                </Suspense>
            </Route>
            <SecureRoute path="/:org([abcdefghijklmnopqrstuvwxyz0123456789-]{6,30})" component={Routes} />
            <Redirect to={defaultPath} />
        </Switch>
    );
};

export default App;
