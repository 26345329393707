import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getCanShowGetStarted, setCanShowGetStarted } from "utils/localStorage";

export const useCanShowGetStarted = () => {
    const {
        location: { state },
    } = useHistory<{ canShowGetStarted?: boolean }>();
    const [canShowGetStartedLS] = useState(() => getCanShowGetStarted());
    useEffect(() => {
        setCanShowGetStarted(null);
    }, []);

    return state?.canShowGetStarted || canShowGetStartedLS;
};
