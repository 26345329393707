import { IBrandingSettings } from "styles/IBrandingSettings";
import { Banner } from "services/generated";

export const getToken = () => window.localStorage.getItem("token");

export const setToken = (token: string | null) => {
    const storage = window.localStorage;
    if (token === null) {
        storage.removeItem("token");
    } else {
        storage.setItem("token", token);
    }
};

export const getPreferredOrg = (user: string) => window.localStorage.getItem(`preferredOrg_${user}`);

export const setPreferredOrg = (user: string, preferredOrg: string | null) => {
    const storage = window.localStorage;
    if (preferredOrg === null) {
        storage.removeItem(`preferredOrg_${user}`);
    } else {
        storage.setItem(`preferredOrg_${user}`, preferredOrg);
    }
};

export const getPreview = (org: string): IBrandingSettings | null => {
    const preview = window.localStorage.getItem(`preview_${org}`);

    return preview ? (JSON.parse(preview) as IBrandingSettings) : null;
};

export const setPreview = (org: string, preview?: IBrandingSettings) => {
    const storage = window.localStorage;
    if (preview === null) {
        storage.removeItem(`preview_${org}`);
    } else {
        storage.setItem(`preview_${org}`, JSON.stringify(preview));
    }
};

export const getClosedBanners = (org: string): Banner[] | null => {
    const closedBanners = window.localStorage.getItem(`closed_banners_${org}`);

    return closedBanners ? (JSON.parse(closedBanners) as Banner[]) : null;
};

export const setClosedBanners = (org: string, closedBanners?: Banner[]) => {
    const storage = window.localStorage;
    if (!closedBanners || !closedBanners.length) {
        storage.removeItem(`closed_banners_${org}`);
    } else {
        storage.setItem(`closed_banners_${org}`, JSON.stringify(closedBanners));
    }
};

export const getCanShowGetStarted = (): boolean => {
    const canShowGetStarted = window.localStorage.getItem("canShowGetStarted");

    return canShowGetStarted === "true";
};

export const setCanShowGetStarted = (state: boolean | null) => {
    const storage = window.localStorage;
    if (!state) {
        storage.removeItem("canShowGetStarted");
    } else {
        storage.setItem("canShowGetStarted", state.toString());
    }
};

export const getOrgAccountingSystem = (org: string) => window.localStorage.getItem(`orgAccountingSystem_${org}`);

export const setOrgAccountingSystem = (org: string, accountingSystemId: string | null) => {
    const storage = window.localStorage;
    if (accountingSystemId === null) {
        storage.removeItem(`orgAccountingSystem_${org}`);
    } else {
        storage.setItem(`orgAccountingSystem_${org}`, accountingSystemId);
    }
};

export const setAccountingsSystemFlow = (org: string, flow?: string) => {
    const storage = window.localStorage;
    if (!flow) {
        storage.removeItem(`accounting_system_flow_${org}`);
    } else {
        storage.setItem(`accounting_system_flow_${org}`, flow);
    }
};

export const getAccountingsSystemFlow = (org: string) => {
    const flow = window.localStorage.getItem(`accounting_system_flow_${org}`);

    return flow;
};

export const getTargetPackage = () => window.localStorage.getItem("new_org_package_target");

export const setTargetPackage = (target: string | null | undefined) => {
    const storage = window.localStorage;
    if (target === null || target === undefined) {
        storage.removeItem("new_org_package_target");
    } else {
        storage.setItem("new_org_package_target", target);
    }
};
