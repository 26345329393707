import { useParams } from "react-router-dom";

import { UserOrgsModel, UserOrgModel } from "services/generated";
import useServerQuery from "utils/server-query";
import { orgsDataName, orgsQuery } from "@client/utils/query";

interface IMyOrgs {
    org: string;
    orgs: UserOrgModel[];
    isItOps: boolean;
    isCreditManagement: boolean;
    isPaycadaBilling: boolean;
    isTestOrg: boolean;
    update: (orgs: UserOrgModel[]) => void;
    isSetupComplete: boolean;
    displayName?: string;
}

const ItOpsOrg = "it-ops";
const CreditManagementOrg = "credit-management";
const PaycadaBilling = "paycada-billing";

export default (): IMyOrgs => {
    const { org } = useParams<{ org: string }>();
    const { data, updateData } = useServerQuery<UserOrgsModel>({
        name: orgsDataName,
        query: orgsQuery,
        disableLoad: true,
        shouldReset: false,
    });

    if (data?.orgs) {
        const orgObj = data.orgs.find((m) => m.id === org)!;
        return {
            org,
            isItOps: org === ItOpsOrg,
            isCreditManagement: org === CreditManagementOrg,
            isPaycadaBilling: org === PaycadaBilling,
            isTestOrg: orgObj.displayName.endsWith("!TESTING!"),
            displayName: orgObj.displayName,
            isSetupComplete: orgObj.isSetupComplete,
            orgs: data.orgs,
            update: (orgs) => updateData({ orgs }),
        };
    }

    throw new Error("Organisations for current user were not loaded");
};
